function returnAppSettings() {
    let settings = {};
    settings.version = 1.0;
    settings.music = true;
    settings.fx = true;
    settings.portrait = true;
    settings.hd = true;
    settings.countdown = false;
    return settings;
}

function returnAppProducts() {
    let settings = [
        { name:'spd', id:'5010', stars:20, title:'Speed me up', desc:'All your cells become faster.', gameplay: {timing:{player:45}} },
        { name:'dbl', id:'5020', stars:30, title:'Double counting', desc:'All your cells receive a higher counting frequency.', gameplay: {counters:{player:2}} },
        { name:'slw', id:'5030', stars:40, title:'Slow them down', desc:'The movement of enemies’ cells become slower.', gameplay: {timing:{ai:20}} },
        { name:'god', id:'5050', stars:100, title:'God mode', desc:'The law of phisics doesn’t apply to you.', gameplay: {timing:{player:60,ai:20},counters:{player:2,ai:0.5}} }
    ];
    return settings;
}

function returnStoreItems() {
    let settings = [
        { id:'8010', stars:20, price: '$3.95' },
        { id:'8020', stars:50, price: '$5.00' },
        { id:'8100', stars:100, price: '$19.95' },
        { id:'8200', stars:200, price: '$34.00' }
    ];
    return settings;
}

function returnGameSettings() {

    let settings = {};

    // all gameplay settings
    settings.gameplay = {};
    settings.gameplay.counters = {};
    settings.gameplay.counters.player = 1; // add every second
    settings.gameplay.counters.ai = 1; // add every second
    settings.gameplay.counters.empty = 0; // add every second
    settings.gameplay.counters.field = 0; // add every second
    settings.gameplay.counters.hole = 0; // add every second
    settings.gameplay.counters.flip = 0; // add every second
    settings.gameplay.counters.min = 0.1; // mini counter
    settings.gameplay.counters.max = 999; // maxi counter
    settings.gameplay.counters.minsplit = 4; // it cannot split or war below this number

    // GLOBAL ticker is set to count every second
    settings.gameplay.counters.tickerSpeed = 1; // ticker speed 1s;

    // for player dragging
    settings.gameplay.allowDrag = {};
    settings.gameplay.allowDrag.player = true; // allow drag on green cells
    settings.gameplay.allowDrag.ai = false; // dragging a.i. is not allowed
    settings.gameplay.allowDrag.empty = false; // empty is not allowed
    settings.gameplay.allowDrag.field = false; // empty is not allowed
    settings.gameplay.allowDrag.hole = false; // empty is not allowed
    settings.gameplay.allowDrag.flip = false; // empty is not allowed

    settings.gameplay.allowToMove = {};
    settings.gameplay.allowToMove.player = true; // allow to move to player
    settings.gameplay.allowToMove.ai = true; // allow to move to ai
    settings.gameplay.allowToMove.empty = true; // allow to move to empty
    settings.gameplay.allowToMove.field = false; // allow to move to: FALSE !!!
    settings.gameplay.allowToMove.hole = false; // allow to move to: FALSE !!!
    settings.gameplay.allowToMove.flip = false; // allow to move to: FALSE !!!

    settings.gameplay.timing =  {}; // cell speed
    settings.gameplay.timing.default = 30; // the speed of the player
    settings.gameplay.timing.player = 30; // the speed of the player
    settings.gameplay.timing.ai = 30; // the speed of the ai
    settings.gameplay.timing.empty = 0; // empty has nothing to speed up
    settings.gameplay.timing.field = 0; // empty has nothing to speed up
    settings.gameplay.timing.hole = 0; // empty has nothing to speed up
    settings.gameplay.timing.flip = 0; // empty has nothing to speed up


    


   


    // variables
    settings.vars = {};
    settings.vars.radius = 40; // radius for dragMe
    settings.vars.margin = 50; // center of the figure
    settings.vars.split = 2; // split a number for clone/move
    settings.vars.warSplit = 3; // durring a war, split a cell by number every second  
    settings.vars.allCells = ['player','ai','empty','field','hole','flip']; // all type of cells  

    settings.vars.hit = 'figure'; // for dragging to DOM element
    settings.vars.trashold = '30%'; // for overlap dragging
    settings.vars.highClass = 'highlight'; // for adding a class to hover draggables
    settings.vars.dragClass = 'dragging'; // for adding a class to hover draggables
    settings.vars.hiddenClass = 'hidden'; // add a hidden class to dom element
    settings.vars.warClass = 'war'; // class durring a war  
    settings.vars.detect_hit = 'detect_hit'; // class for detects hits      
    settings.vars.allClasses = ['player','ai','empty','field','hole','flip','war','special','burst'];  

    // cell animation & background animations in cells
    settings.vars.speedIdle = 25; // speed during idle
    settings.vars.speedMove = 100; // speed when is set for a drag
    settings.vars.speedWar = 300; // speed during fighting

    // stage & grid, default position
    // grid values, min/max and positions
    settings.grids = {};
    settings.grids.margin = 2;
    settings.grids.orientation = 'landscape'; // landscape or portrait
    settings.grids.forceLandscape = 620; // for landscape, a hight must be less than 620
    settings.grids.min = 20; // 40/2;
    settings.grids.max = 40; // 80/2;
    settings.grids.landscape = {};  // grid for landscape position  
    settings.grids.landscape.gridX = 28; // 14*2;
    settings.grids.landscape.gridY = 16; // 8*2;
    settings.grids.portrait = {};  // grid for portrait position  
    settings.grids.portrait.gridX = 16; // 8*2
    settings.grids.portrait.gridY = 28; // 14*2
    settings.grids.drawGridDots = false; // draw grid dots, for testing
    settings.grids.mirrorY = true; // it looks better when axis Y is mirrored in landscape


    // stage & grid, default position
    // GAME COMPONENT UPDATES these values
    settings.gridPosition = {};
    settings.gridPosition.orientation = 'landscape'; // it changes on rotate screen
    settings.gridPosition.rectWidth = 10; // calculated size based on grid/screen
    settings.gridPosition.rectHeight = 10; // calculated size based on grid/screen
    settings.gridPosition.stageWidth = 320; // true size of the screen, window.width()
    settings.gridPosition.stageHeight = 320; // true size of the screen window.height()

    settings.gridPosition.SVGscale = 0.5; // scale factor of outer & inner cell     
    settings.gridPosition.SVGblob = 0.4; // radius of moving blob
    settings.gridPosition.SVGroll = '40%'; // radius of roll over fx
    settings.gridPosition.SVGradius = 40; // appro. diameter of the cell
    settings.gridPosition.fontSize = "14px"; // font size in PX

    // for testing
    settings.test = {};
    settings.test.testmode = true;
    settings.test.allowSkipLevels = true; // go to any level by manualy alter a url
    settings.test.allowPurchase = true; // allow purchase stars

    // return settings
    return settings;    

    
}

