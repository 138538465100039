function returnGameLevels() {

  let levelData = {"stage":[{"name":"The beginning","comment":"Test stage","start":1,"end":10,"extrastars":15},
  {"name":"Dark cells rising","comment":"Who dares wins","start":11,"end":30,"extrastars":20},
  {"name":"Total Annihilation","comment":"Beginners luck, no more","start":31,"end":60,"extrastars":20},
  {"name":"No hope","comment":"Bring me no hope","start":61,"end":75,"extrastars":30},
  {"name":"The final countdown","comment":"It is the final countdown","start":76,"end":100,"extrastars":30}],"levels":[{"index":0},
  {"index":1,"time":40,"ai":"level_ONE","positions":[{"whois":"player","counter":70,"x":8,"y":8},
  {"whois":"ai","counter":20,"x":8,"y":18}]},
  {"index":2,"time":60,"ai":"level_ONE","positions":[{"whois":"player","counter":50,"x":4,"y":8},
  {"whois":"player","counter":60,"x":12,"y":9},
  {"whois":"ai","counter":40,"x":8,"y":17}]},
  {"index":3,"time":"50","ai":"level_ONE","positions":[{"whois":"player","counter":90,"x":8,"y":8},
  {"whois":"ai","counter":20,"x":8,"y":17},
  {"whois":"ai","counter":10,"x":5,"y":21},
  {"whois":"ai","counter":10,"x":11,"y":21}]},
  {"index":4,"time":"60","ai":"level_ONE","positions":[{"whois":"player","counter":90,"x":5,"y":8},
  {"whois":"player","counter":20,"x":11,"y":8},
  {"whois":"ai","counter":60,"x":8,"y":15},
  {"whois":"ai","counter":10,"x":13,"y":19},
  {"whois":"ai","counter":10,"x":3,"y":19}]},
  {"index":5,"time":"75","ai":"level_ONE","positions":[{"whois":"player","counter":80,"x":5,"y":9},
  {"whois":"player","counter":50,"x":11,"y":11},
  {"whois":"ai","counter":30,"x":8,"y":19},
  {"whois":"ai","counter":50,"x":4,"y":19},
  {"whois":"ai","counter":50,"x":12,"y":22}]},
  {"index":6,"time":"100","ai":"level_ONE","positions":[{"whois":"player","counter":20,"x":2,"y":7},
  {"whois":"player","counter":30,"x":6,"y":6},
  {"whois":"empty","counter":20,"x":8,"y":15},
  {"whois":"ai","counter":20,"x":2,"y":23},
  {"whois":"player","counter":30,"x":10,"y":6},
  {"whois":"player","counter":20,"x":14,"y":7},
  {"whois":"ai","counter":30,"x":6,"y":22},
  {"whois":"ai","counter":30,"x":10,"y":22},
  {"whois":"ai","counter":20,"x":14,"y":23}]},
  {"index":7,"time":"70","ai":"level_ONE","positions":[{"whois":"player","counter":50,"x":3,"y":5},
  {"whois":"player","counter":50,"x":10,"y":17},
  {"whois":"player","counter":70,"x":7,"y":22},
  {"whois":"ai","counter":150,"x":4,"y":15},
  {"whois":"empty","counter":20,"x":12,"y":8}]},
  {"index":8,"time":"85","ai":"level_ONE","positions":[{"whois":"player","counter":30,"x":5,"y":10},
  {"whois":"player","counter":30,"x":11,"y":10},
  {"whois":"ai","counter":20,"x":11,"y":22},
  {"whois":"ai","counter":30,"x":8,"y":19},
  {"whois":"ai","counter":20,"x":2,"y":17},
  {"whois":"ai","counter":20,"x":14,"y":17},
  {"whois":"ai","counter":20,"x":5,"y":22},
  {"whois":"player","counter":40,"x":8,"y":7}]},
  
  {"index":11,"ai":"level_TWO","positions":[{"whois":"ai","counter":20,"x":4,"y":19},{"whois":"ai","counter":20,"x":12,"y":21},{"whois":"player","counter":30,"x":4,"y":7},{"whois":"player","counter":40,"x":12,"y":6},{"whois":"empty","counter":30,"x":10,"y":15},{"whois":"flip","counter":0,"x":4,"y":13}]},

  {"index":11,"ai":"level_TWO","positions":[{"whois":"ai","counter":20,"x":4,"y":19},{"whois":"ai","counter":20,"x":12,"y":21},{"whois":"player","counter":30,"x":4,"y":7},{"whois":"player","counter":40,"x":12,"y":6},{"whois":"empty","counter":30,"x":10,"y":15},{"whois":"field","counter":0,"x":4,"y":13}]},

  {"index":11,"ai":"level_TWO","positions":[{"whois":"ai","counter":20,"x":4,"y":19},
  {"whois":"ai","counter":20,"x":12,"y":21},
  {"whois":"player","counter":30,"x":4,"y":7},
  {"whois":"player","counter":40,"x":12,"y":6},
  {"whois":"empty","counter":30,"x":10,"y":15},
  {"whois":"field","counter":0,"x":4,"y":14}]},
  {"index":12,"time":"85","ai":"level_TWO","positions":[{"whois":"player","counter":40,"x":13,"y":8},
  {"whois":"player","counter":30,"x":3,"y":7},
  {"whois":"ai","counter":10,"x":2,"y":19},
  {"whois":"ai","counter":20,"x":7,"y":22},
  {"whois":"ai","counter":20,"x":13,"y":18},
  {"whois":"empty","counter":10,"x":6,"y":16},
  {"whois":"hole","counter":0,"x":8,"y":13}]},
  {"index":13,"time":100,"ai":"level_TWO","positions":[{"whois":"player","counter":100,"x":3,"y":5},
  {"whois":"player","counter":100,"x":8,"y":6},
  {"whois":"player","counter":100,"x":13,"y":7},
  {"whois":"ai","counter":100,"x":3,"y":20},
  {"whois":"ai","counter":100,"x":8,"y":21},
  {"whois":"ai","counter":100,"x":13,"y":22},
  {"whois":"empty","counter":10,"x":5,"y":14},
  {"whois":"empty","counter":10,"x":10,"y":15}]},
  {"index":14,"time":"80","ai":"level_TWO","positions":[{"whois":"player","counter":60,"x":8,"y":25},
  {"whois":"player","counter":60,"x":8,"y":3},
  {"whois":"ai","counter":30,"x":13,"y":20},
  {"whois":"ai","counter":20,"x":3,"y":20},
  {"whois":"ai","counter":30,"x":3,"y":8},
  {"whois":"ai","counter":20,"x":13,"y":8},
  {"whois":"empty","counter":20,"x":8,"y":14},
  {"whois":"hole","counter":0,"x":8,"y":21},
  {"whois":"hole","counter":0,"x":8,"y":7}]},
  {"index":15,"time":"140","ai":"level_TWO","positions":[{"whois":"player","counter":180,"x":8,"y":13},
  {"whois":"ai","counter":20,"x":8,"y":4},
  {"whois":"ai","counter":20,"x":2,"y":13},
  {"whois":"ai","counter":20,"x":14,"y":13},
  {"whois":"ai","counter":20,"x":8,"y":22},
  {"whois":"ai","counter":20,"x":3,"y":7},
  {"whois":"ai","counter":20,"x":13,"y":7},
  {"whois":"ai","counter":20,"x":3,"y":19},
  {"whois":"ai","counter":20,"x":13,"y":19},
  {"whois":"field","counter":0,"x":8,"y":9},
  {"whois":"field","counter":0,"x":8,"y":17}]},
  {"index":16,"time":"70","ai":"level_TWO","positions":[{"whois":"field","counter":0,"x":12,"y":14},
  {"whois":"hole","counter":0,"x":4,"y":14},
  {"whois":"ai","counter":20,"x":3,"y":20},
  {"whois":"ai","counter":20,"x":13,"y":20},
  {"whois":"player","counter":30,"x":3,"y":7},
  {"whois":"player","counter":30,"x":13,"y":7},
  {"whois":"empty","counter":30,"x":8,"y":15},
  {"whois":"field","counter":0,"x":8,"y":8},
  {"whois":"hole","counter":0,"x":8,"y":19}]},
  {"index":17,"ai":"level_TWO","positions":[{"whois":"ai","counter":40,"x":8,"y":4},
  {"whois":"ai","counter":40,"x":14,"y":14},
  {"whois":"ai","counter":40,"x":2,"y":14},
  {"whois":"ai","counter":40,"x":8,"y":24},
  {"whois":"empty","counter":20,"x":8,"y":14},
  {"whois":"player","counter":30,"x":3,"y":20},
  {"whois":"player","counter":30,"x":13,"y":20},
  {"whois":"player","counter":30,"x":3,"y":8},
  {"whois":"player","counter":30,"x":13,"y":8},
  {"whois":"hole","counter":0,"x":8,"y":18},
  {"whois":"hole","counter":0,"x":8,"y":10}]},
  {"index":18,"time":"140","ai":"level_TWO","positions":[{"whois":"player","counter":30,"x":5,"y":15},
  {"whois":"player","counter":40,"x":8,"y":10},
  {"whois":"player","counter":30,"x":11,"y":15},
  {"whois":"ai","counter":20,"x":3,"y":22},
  {"whois":"ai","counter":10,"x":8,"y":22},
  {"whois":"ai","counter":20,"x":13,"y":22},
  {"whois":"ai","counter":20,"x":3,"y":4},
  {"whois":"ai","counter":10,"x":8,"y":4},
  {"whois":"ai","counter":20,"x":13,"y":4},
  {"whois":"hole","counter":0,"x":8,"y":18},
  {"whois":"hole","counter":0,"x":12,"y":10},
  {"whois":"hole","counter":0,"x":4,"y":10}]},
  {"index":19,"time":"60","ai":"level_TWO","positions":[{"whois":"ai","counter":20,"x":3,"y":19},
  {"whois":"ai","counter":20,"x":13,"y":19},
  {"whois":"player","counter":50,"x":8,"y":7},
  {"whois":"field","counter":0,"x":5,"y":14},
  {"whois":"field","counter":0,"x":11,"y":14},
  {"whois":"hole","counter":0,"x":8,"y":20},
  {"whois":"empty","counter":10,"x":8,"y":16}]},
  {"index":20},
  {"index":21,"time":60,"ai":"level_ONE","positions":[{"whois":"player","counter":20,"x":11,"y":10},
  {"whois":"ai","counter":20,"x":8,"y":20}]},
  {"index":22},
  {"index":23,"time":60,"ai":"level_ONE","positions":[{"whois":"player","counter":30,"x":5,"y":5},
  {"whois":"player","counter":20,"x":11,"y":10},
  {"whois":"ai","counter":20,"x":8,"y":20}]},
  {"index":24},
  {"index":25,"time":60,"ai":"level_ONE","positions":[{"whois":"player","counter":30,"x":5,"y":5},
  {"whois":"player","counter":20,"x":11,"y":10},
  {"whois":"ai","counter":20,"x":8,"y":20}]},
  {"index":26},
  {"index":27,"time":60,"ai":"level_ONE","positions":[{"whois":"player","counter":30,"x":5,"y":5},
  {"whois":"player","counter":20,"x":11,"y":10},
  {"whois":"ai","counter":20,"x":8,"y":20}]},
  {"index":28},
  {"index":29,"time":60,"ai":"level_ONE","positions":[{"whois":"player","counter":30,"x":5,"y":5},
  {"whois":"player","counter":20,"x":11,"y":10},
  {"whois":"ai","counter":20,"x":8,"y":20}]},
  {"index":30},
  {"index":31},
  {"index":32},
  {"index":33},
  {"index":34},
  {"index":35},
  {"index":36},
  {"index":37},
  {"index":38},
  {"index":39},
  {"index":40},
  {"index":41},
  {"index":42},
  {"index":43},
  {"index":44},
  {"index":45},
  {"index":46},
  {"index":47},
  {"index":48},
  {"index":49},
  {"index":50},
  {"index":51},
  {"index":52},
  {"index":53},
  {"index":54},
  {"index":55},
  {"index":56},
  {"index":57},
  {"index":58},
  {"index":59},
  {"index":60},
  {"index":61,"time":"100","ai":"level_ONE","positions":[{"whois":"player","counter":20,"x":3,"y":8},
  {"whois":"ai","counter":100,"x":13,"y":8},
  {"whois":"ai","counter":20,"x":13,"y":22},
  {"whois":"player","counter":20,"x":3,"y":22},
  {"whois":"flip","counter":0,"x":3,"y":15},
  {"whois":"flip","counter":0,"x":8,"y":8},
  {"whois":"flip","counter":0,"x":13,"y":15},
  {"whois":"flip","counter":0,"x":8,"y":22},
  {"whois":"hole","counter":0,"x":8,"y":15}]},
  {"index":62},
  {"index":63},
  {"index":64},
  {"index":65},
  {"index":66},
  {"index":67},
  {"index":68},
  {"index":69},
  {"index":70,"ai":"level_TWO","positions":[{"whois":"player","counter":80,"x":6,"y":23},
  {"whois":"player","counter":60,"x":3,"y":15},
  {"whois":"ai","counter":30,"x":3,"y":3},
  {"whois":"ai","counter":30,"x":12,"y":3},
  {"whois":"ai","counter":40,"x":8,"y":7},
  {"whois":"empty","counter":40,"x":11,"y":15},
  {"whois":"ai","counter":50,"x":14,"y":23}]},
  {"index":71},
  {"index":72},
  {"index":73},
  {"index":74},
  {"index":75},
  {"index":76},
  {"index":77},
  {"index":78},
  {"index":79},
  {"index":80},
  {"index":81},
  {"index":82},
  {"index":83},
  {"index":84},
  {"index":85},
  {"index":86},
  {"index":87},
  {"index":88},
  {"index":89},
  {"index":90},
  {"index":91},
  {"index":92},
  {"index":93},
  {"index":94},
  {"index":95},
  {"index":96},
  {"index":97},
  {"index":98},
  {"index":99},
  {"index":100}]}
    return levelData;

}